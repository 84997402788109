import axios from 'axios';

export async function httpRequest(url, method = "GET", data = null, headers = {}, auth = null,otherConfig) {
    try {
        const axiosConfig = {
            method,
            url,
            withCredentials: true,
            ...otherConfig,
            headers: {
                'Content-Type': 'application/json',
                ...(auth ? {Authorization: `Bearer ${auth}`} : {}),
                ...headers
            },
            data: data
        };

        const response = await axios(axiosConfig);
        return {data: response.data, response}
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export function customCompare(a, b) {
    const getFirstAlphabetic = (username) => {
        const matches = username.match(/[a-zA-Z]/);
        return matches ? matches[0].toLowerCase() : '';
    };

    const alphabeticA = getFirstAlphabetic(a.USERNAME);
    const alphabeticB = getFirstAlphabetic(b.USERNAME);


    if (alphabeticA === '' && alphabeticB === '') {
        return 0;
    } else if (alphabeticA === '') {
        return 1;
    } else if (alphabeticB === '') {
        return -1;
    } else {

        return alphabeticA.localeCompare(alphabeticB);
    }
}



export const saveToLocalStorage = (key, value, cond) => {
    try {
        return cond ? localStorage.setItem(key, value) : sessionStorage.setItem(key, value);
    } catch (error) {
        console.error('Erreur lors de la sauvegarde dans le stockage local:', error);
    }
};


export function formatDate(timeInMs) {
    const date = new Date(timeInMs);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // les mois sont indexés de 0 à 11, d'où le +1
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
}

export function formatTime(timeInMs) {
    const date = new Date(timeInMs);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}h ${minutes}m ${seconds}s`;
}
export function UserIsStaff(uuid) {

     const staff = {
         'f5b391ac462b413493e04b006b0e0899': 'Axeldu18',
         'e806e52f38ca4446bd2be62e70af5fc1': 'ChupaShups',
         '98816bd183ad4e42a151e7235d63609b': 'Delta_Element',
         '99c06a5c345e4c868b7d665fae7397df': 'PepyNox',
         '5b043e018f9b4ec4990874b698be566e': 'Thomaslap'
     }

     return staff[uuid];
}

const exportedFunction = {
    httpRequest,
    customCompare,
    saveToLocalStorage,
    formatDate,
    formatTime,
    UserIsStaff
};

export default exportedFunction

// const [loadingPercentage, setLoadingPercentage] = useState(0);
//
// const calculateLoadingPercentage = (currentIndex, totalItems) => {
//     if (totalItems === 0)  return 0;
//     setLoadingPercentage(Math.min(100, (currentIndex / totalItems) * 100));
// };

{/*<ProgressBar value={loadingPercentage} className="grid grid-cols-[1fr,auto] gap-4 text-green-300  ">*/}
{/*    {({percentage, valueText}) => <>*/}
{/*        <div className="col-start-1 col-end-3 grid overflow-hidden will-change-transform box-border ">*/}
{/*            <div className="bg-green-500 h-[8px]" style={{width: percentage + '%'}} />*/}
{/*        </div>*/}
{/*    </>}*/}
{/*</ProgressBar>*/}