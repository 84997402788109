import React from 'react';
import {Cell, Column, Header, Row, Table, TableBody, TableHeader} from "react-aria-components";
import {formatDate, UserIsStaff} from "../../utils/utils";

const SanctionList = (props) => {
    const sanctions = [...props.data];

    return (
        <>
            <Header>Sanction</Header>
            <Table className="w-full " renderEmptyState={() => "Aucune sanction"}>
                <TableHeader>
                    <Column isRowHeader>AT</Column>
                    <Column>BY</Column>
                    <Column>END</Column>
                    <Column>REASON</Column>
                    <Column>TYPE</Column>
                </TableHeader>
                <TableBody >
                    {sanctions.map((sanction,index) => (
                        <Row key={index} className="text-center" id={sanction._id} item={sanctions}>
                            <Cell>{formatDate(sanction.AT)}</Cell>
                            <Cell>{UserIsStaff(sanction.BY)}</Cell>
                            <Cell>{formatDate(sanction.END)}</Cell>
                            <Cell>{sanction.REASON}</Cell>
                            <Cell>{sanction.TYPE}</Cell>
                        </Row>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};
export default SanctionList