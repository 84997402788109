import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useButton} from '@react-aria/button';
import {clsx} from "clsx";
import LoaderComponent from "../Loader/Loader.jsx";


const Buttons = ({
                     children,
                     className,
                     initialIsPending = false,
                     disabled = false,
                     type = 'button',
                     withIcons = false,
                     iconsPosition = 'left',
                     icon,
                     onClick,
                     variant = "classic",
                     size = "medium",
                 }) => {
    let variantStyles;
    let sizeStyle;

    const [isPending, setIsPending] = useState(initialIsPending);
    const ref = useRef();
    const {buttonProps} = useButton({
            onPress: onClick,
            isDisabled: disabled || isPending, type
        },
        ref);


    useEffect(() => {
        setIsPending(initialIsPending);
    }, [initialIsPending]);


    switch (variant) {

        case "light" : {
            variantStyles = "hover:bg-neutral-400 transition-bg duration-500 bg-white text-black text-lg font-medium center p-1 rounded-md shadowCustom"
            break;
        }

        default : {
            variantStyles = "hover:bg-neutral-700 transition-bg duration-500 bg-gray text-white text-lg font-medium center p-1 rounded-md shadowCustom"
            break;
        }

    }

    switch (size) {

        case "sm" : {
            sizeStyle = "w-[80px] h-[25px] text-xs "
            break;
        }

        case "large" : {
            sizeStyle = "w-[170px] h-[60px] text-base"
            break;
        }

        case "xl" : {
            sizeStyle = "w-[200px] h-[70px] text-lg"
            break;
        }

        default : {
            sizeStyle = "w-[150px] h-[50px] text-sm"
            break;
        }

    }


    return (
        <button
            {...buttonProps}
            ref={ref}
            className={`
                ${clsx(sizeStyle, variantStyles, className)} 
                ${disabled ? 'cursor-not-allowed hover opacity-80 no-hover' : ''}
                ${isPending ? 'cursor-wait no-hover' : ''}
            `}
            type={type}
            disabled={disabled || isPending}

        >
            {!isPending && withIcons && iconsPosition === 'left' && <span>{icon}</span>}
            {isPending ? <LoaderComponent size={size} variant={variant}/> : children}
            {!isPending && withIcons && iconsPosition === 'right' && <span>{icon}</span>}
        </button>
    );
};

Buttons.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    initialIsPending: PropTypes.bool, // Met à jour la propriété pour initialIsPending
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    withIcons: PropTypes.bool,
    iconsPosition: PropTypes.oneOf(['left', 'right']),
    icon: PropTypes.node,
    onClick: PropTypes.func,
};

export default Buttons;