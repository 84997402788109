import React from 'react';
import {Cell, Column, Header, Row, Table, TableBody, TableHeader} from "react-aria-components";
import {formatDate, UserIsStaff} from "../../utils/utils";


const Achievements = (props) => {

    const Achievements = [...props.data];

    return (
        <div className=" ">
            <div className="flex items-center justify-between ">
                <Header>achievements</Header>
                <p>{Achievements.length} / 194</p>
            </div>
            <Table className="w-full h-full overflow-scroll ">
                <TableHeader>
                    <Column isRowHeader>CODE</Column>
                    <Column>REWARDED_AT</Column>
                </TableHeader>
                <TableBody className="">
                    {Achievements.map((achievement,index) => (
                        <Row key={index} className="text-center" id={achievement._id} item={Achievements}>
                            <Cell>{achievement.CODE}</Cell>
                            <Cell>{formatDate(achievement.REWARDED_AT)}</Cell>

                        </Row>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default Achievements

