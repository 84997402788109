import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import store  from "./store/store.js";
import {Provider} from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ToastContainer
            autoClose={1000}
            hideProgressBar={false}
            closeOnClick
            pauseOnFocusLoss
            draggable/>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);


