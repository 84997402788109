import React from 'react';

import {clsx} from "clsx";
import './loader.css'

const LoaderComponent = ({size = "medium", variant = "classic"}) => {
    let sizeStyle;
    const color = variant !== "classic" ? "bg-black" : "bg-wight"
    switch (size) {
        case "sm" : {
            sizeStyle = "loader__sm"
            break;
        }

        case "large" : {
            sizeStyle = "loader__large"
            break;
        }

        case "xl" : {
            sizeStyle = "loader__xl"
            break;
        }

        default : {
            sizeStyle = "loader__medium"
            break;
        }

    }

    return (
        <span className={clsx(color, "loader", sizeStyle)}></span>
    )
}

export default LoaderComponent