import React, {useEffect, useState} from 'react';
import {deleteAchievementsListData, getAchievementsListData, setAchievementsListData} from "../../services/services";
import {
    Button,
    Cell, Checkbox, Collection,
    Column,
    Dialog,
    DialogTrigger,
    Heading,
    Modal,
    Row,
    Table,
    TableBody,
    TableHeader, useTableOptions
} from "react-aria-components";
import * as yup from 'yup';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDeleteForever } from "react-icons/md";
import {FiPlus} from "react-icons/fi";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import getMessageMapping from "../../utils/Message";
import Alerte from "../../components/Alerte/Alerte";
import {retry} from "@reduxjs/toolkit/query";

const categories = [
    "Selectionnez votre categorie",
    "VIE",
    "ETE2021",
    "CELESTE",
    "TECHNOLOGY",
    "HALLOWEEN2021",
    "HIVER2021",
    "GENERAL",
    "MAINSTREET",
    "HALLOWEEN2020",
    "EVENT",
    "NOEL2020",
    "EAU",
    "AQUAEVENT",
    "MAGIE",
    "FEU"
];

const types = [
    "Selectionnez votre type",
    "RIDE",
    "DISCOVER ",
    "SECRET",
    "INTERACT",
    "PARKOUR"
    
];
/**

 CODE         string
 NAME         string
 DESCRIPTION  string
 CATEGORY     string
 TYPE         string
 REGION       String
 VISIBLE      boolean
 */

const RowOptionsMenu = (props) => {
    const [isOpen, setOpen] = useState(false);


    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 30;

    const Achievement = props.data

    

    const totalPages = Math.ceil(Achievement.length / ITEMS_PER_PAGE);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const visibleAchievements = Achievement.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (

    <DialogTrigger>
        <Button
            onPress={()=>{
                setOpen(true)
            }}
            className=" center gap-3 bg-blue-500 text-white p-2 rounded hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray ml-2"><FiPlus/>  Voir la liste des achievement</Button>


        <Modal className="ring-1 ring-[#5b5c64] w-[70%] h-[85%] bg-[#212121] p-2 rounded" isOpen={isOpen} onOpenChange={setOpen}>
            <Dialog className="w-full h-full">
                {({ close }) => (
                    <div className="relative w-full h-full ">
                        <Heading slot="title"
                                 className="text-[25px] px-2 py-1 text-white font-bold uppercase">Achievement</Heading>
                        <div className="w-full h-[87%] bg-gray p-4 rounded shadowCustom overflow-scroll">
                        <Table className="w-full h-full text-white">
                            <TableHeader className="border-b">
                                <Column isRowHeader className="text-center pl-2">CODE</Column>
                                <Column className="text-center pl-2">NAME</Column>
                                <Column className="text-center pl-2">CATEGORY</Column>
                                <Column className="text-center pl-2">DESCRIPTION</Column>
                                <Column className="text-center pl-2">TYPE</Column>
                                <Column className="text-center pl-2">VISIBLE</Column>
                            </TableHeader>
                            <TableBody className="table-auto text-center">
                                {visibleAchievements.map((achievement, index) => (
                                    <Row key={index} className="text-center pb-2 border-b border-neutral-500" id={index} item={achievement}>
                                        <Cell>{achievement.CODE ||achievement.code  }</Cell>
                                        <Cell>{achievement.NAME ||achievement.name }</Cell>
                                        <Cell>{achievement.CATEGORY ||achievement.category }</Cell>
                                        <Cell>{achievement.DESCRIPTION ||achievement.description }</Cell>
                                        <Cell>{achievement.TYPE ||achievement.type }</Cell>
                                        <Cell className="center flex-col mt-1">
                                            <Checkbox slot="selection" isSelected={!!achievement.VISIBLE ||!!achievement.visible }>
                                                <div className="checkbox">
                                                    <svg viewBox="0 0 18 18" aria-hidden="true">
                                                        <polyline points="1 9 7 14 15 4" />
                                                    </svg>
                                                </div>
                                            </Checkbox>
                                        </Cell>
                                    </Row>
                                ))}
                            </TableBody>
                        </Table>
                        </div>
                        <div className="relative flex justify-center mt-4">

                            <div className="flex justify-center">
                                <Button onClick={handlePrevPage}><FaArrowLeftLong/></Button>
                                {[...Array(totalPages).keys()].map((page) => (
                                    <Button
                                        key={page + 1}
                                        className={`mx-1 ${currentPage === page + 1 ? 'bg-blue-500' : 'bg-gray-500'} text-white px-3 py-1 rounded`}
                                        onPress={() => handlePageChange(page + 1)}
                                    >
                                        {page + 1}
                                    </Button>
                                ))}
                                <Button onClick={handleNextPage}><FaArrowRightLong/></Button>
                            </div>

                            <Button
                                className="absolute right-0 ring-1 ring-[#5b5c64] p-1 px-3  mr-1 text-white rounded bg-[#000] hover:bg-[#3e3e45] transition bg duration-500"
                                onPress={close}
                            >
                                Fermer
                            </Button>
                        </div>


                    </div>
                )}
            </Dialog>
        </Modal>

    </DialogTrigger>
    );
}


function MyTableHeader({columns, children}) {
    let {allowsDragging} = useTableOptions();

    return (
        <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md">
            {allowsDragging && <Column/>}
            <Collection items={columns}>
                {(column) => (
                    <Column className="p-1 text-white " key={column.id} isRowHeader={column.isRowHeader}>
                        {column.name}
                    </Column>
                )}
            </Collection>
            <Column></Column>
        </TableHeader>
    );
}


const Achievements = () => {
    const schema = yup.object().shape({
        code: yup.string().required('Le champ CODE est requis'),
        name: yup.string().required('Le champ NAME est requis'),
        description: yup.string().required('Le champ DESCRIPTION est requis'),
        category: yup.string().required('Le champ CATEGORY est requis'),
        type: yup.string().required('Le champ TYPE est requis'),
        region: yup.string().required('Le champ REGION est requis'), 
        visible: yup.boolean(), // Champ facultatif
    });

    const [Achievement, setAchievement] = useState([]);
    const [AddedAchievement, setAddedAchievement] = useState([]);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        code: "",
        name: "",
        description: "",
        category: "",
        type:"",
        region: "",
        visible: false,
    });

    useEffect(() => {
        (async () => {
            const response = await getAchievementsListData();
            setAchievement(response.data.data)
        })()
    }, []);

    const handleDelete = async (e) =>{
        
        const codeToDelete = e.target.dataset.code;
        try {
            const response = await deleteAchievementsListData(codeToDelete.toString());

            if(response.response.status === 200) {
                const updatedAddedAchievements = AddedAchievement.filter(achievement => achievement.code !== codeToDelete);
                setAddedAchievement(updatedAddedAchievements);

                const response = await getAchievementsListData();
                setAchievement(response.data.data);

                toast.success('achievement supprimer');
            }
        }    catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {

        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            await schema.validate(formData, { abortEarly: false });
            setFormErrors({});
            setIsSubmitSuccessful(true);

            try {
                const response = await setAchievementsListData(JSON.stringify(formData));
   
                if(response.response.status === 200 ) {
                    const { code, name, category } = formData;
                    setAddedAchievement((prevAchievements) => [
                        ...prevAchievements,
                        { code, name, category}
                    ]);

                    setAchievement((prevAchievements) => [
                        ...prevAchievements,
                        formData,
                    ]);

                    resetForm();

                    toast.success(getMessageMapping(response.response.data.CODE));
                }
            }    catch (error) {
                if(error.response.data.code === "ACHIEVEMENTS_DUPLICATION_ERROR") {
                    const validationErrors = {};
                    validationErrors['code'] = "CODE doit être unique";
                    setFormErrors(validationErrors);
                    return;
                }
                console.log(error)
            }


        } catch (error) {

            const validationErrors = {};
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.message;
            });
            setFormErrors(validationErrors);
            console.error(validationErrors);
        }
    };


    const resetForm = () => {
        setFormErrors({});
    };

    const columns = [
        {name: "code", id: "code"},
        {name: "name", id: "name", isRowHeader: true},
        {name: "category", id: "category"},
    ];


    return (
        <section className=" relative bg-gray-100">

            <h1 className="font-black text-4xl uppercase text-center p-4">Ajout des achievements:</h1>
            <div className="absolute right-0 top-5"><RowOptionsMenu data={Achievement}/></div>
            <section className="bg-[#23282f] w-full h-[85vh] rounded-md p-4  flex gap-4 justify-around items-center">
                <div className="w-[45%] h-full bg-gray p-4 rounded shadowCustom ">
                    <form className="h-full flex flex-col justify-between overflow-scroll"
                          onSubmit={onSubmit}
                    >

                        <div
                            className="text-xl font-bold bg-white border-b border-gray-700 text-center p-2 rounded shadow-md">
                            Formulaire d'ajout d'achievements
                        </div>

                        <div className="my-4">
                            <label className="block text-white">CODE</label>
                            <input
                                aria-label="code"
                                type="text"
                                className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                name="code"
                                value={formData.code.toUpperCase()}
                                onChange={handleChange}
                            />
                            {formErrors.code && <p className="text-red-500">{formErrors.code}</p>}
                        </div>

                        <div className="my-4">
                            <label className="block text-white">NAME</label>
                            <input
                                aria-label="name"
                                name="name"
                                type="text"
                                className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {formErrors.name && <p className="text-red-500">{formErrors.name}</p>}
                        </div>

                        <div className="my-4">
                            <label className="block text-white">DESCRIPTION</label>
                            <input
                                aria-label="description"
                                name="description"
                                type="text"
                                className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                value={formData.description}
                                onChange={handleChange}
                            />
                            {formErrors.description && <p className="text-red-500">{formErrors.description}</p>}
                        </div>

                        <div className="my-4">
                            <label className="block text-white">CATEGORY</label>
                            <select
                                aria-label="category"
                                name="category"
                                className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                value={formData.category}
                                onChange={handleChange}
                            >
                                {categories.map((category) => (
                                    <option key={category} value={categories[0] === category ? '' : category}>
                                        {category}
                                    </option>
                                ))}
                            </select>
                            {formErrors.category && <p className="text-red-500">{formErrors.category}</p>}
                        </div>

                        <div className="my-4">
                            <label className="block text-white">TYPE</label>
                            <select
                                aria-label="type"
                                name="type"
                                className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                value={formData.type}
                                onChange={handleChange}
                            >
                                {types.map((type) => (
                                    <option key={type} value={types[0] === type ? '' : type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                            {formErrors.type && <p className="text-red-500">{formErrors.type}</p>}
                        </div>

                        <div className="my-4">
                            <label className="block text-white">REGION</label>
                            <input
                                aria-label="region"
                                name="region"
                                type="text"
                                className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                value={formData.region}
                                onChange={handleChange}
                            />
                            {formErrors.region && <p className="text-red-500">{formErrors.region}</p>}
                        </div>

                        <div className="my-4">
                            <label className="block text-white flex items-center">
                                VISIBLE
                                <input
                                    aria-label="visible"
                                    name="visible"
                                    type="checkbox"
                                    className="ml-2"
                                    value={formData.visible}
                                    onChange={handleChange}

                                />
                                <p className="pl-2">affiche les descriptions dans les GUI</p>
                            </label>
                        </div>

                        <div className=" center relative my-1 center">
                            <button
                                type="submit"
                                className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                            >
                                Submit
                            </button>
                            <button
                                type="reset"
                                onClick={() => {
                                    resetForm();
                                }}
                                className="bg-blue-500 text-white p-2 rounded hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray ml-2"
                            >
                                Reset
                            </button>

                        </div>
                    </form>
                </div>
                <div className="w-[45%] bg-[#323232] shadowCustom h-[100%] mt-2 rounded !overflow-scroll">
                    <Table className="w-full max-w-[100] max-h-[73vh] text-white">
                        <MyTableHeader columns={columns} className='border-[#3f3f46] border-2 text-white'/>
                        <TableBody className="text-center bg-gray text-white"
                                   renderEmptyState={() => "Aucun Achievement ajoutée"}
                        >
                            {AddedAchievement.map((achievement, index) => (
                                <Row key={index} className="border-[#3f3f46] border-2 text-white">
                                    <Cell className="w-1/3">{achievement.code}</Cell>
                                    <Cell className="w-1/3">{achievement.name}</Cell>
                                    <Cell className="w-1/3">{achievement.category}</Cell>
                                    <Cell className="w-1/3"><Button data-code={achievement.code} onPress={handleDelete} className=" text-xl center bg-transparent border-none"><MdDeleteForever /></Button></Cell>
                                    </Row>
                                ))}
                            </TableBody>

                    </Table>
                </div>
            </section>
        </section>

    );
};



export default Achievements;