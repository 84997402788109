import {isAuthenticated} from "./services.js";

const ALLOWED_ACCESS = 'ALLOWED_ACCESS';
const NO_TOKEN_PROVIDED = 'NO_TOKEN_PROVIDED';

function handleNoToken(code) {
    if (code === NO_TOKEN_PROVIDED) {
        throw new Error(NO_TOKEN_PROVIDED);
    }
}

export async function isAuthenticate() {
    const {data} = await isAuthenticated();
    handleNoToken(data.code);
    if (data.code !== ALLOWED_ACCESS) {
        return false;
    }
    return {valid: data.Authenticated, code :data.code};
}


const exportedFunctions = {
    isAuthenticated,
}


export default exportedFunctions