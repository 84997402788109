import "../../global.css";
import React from "react";
import {
    Cell,
    Collection,
    Column,
    Row,
    Table,
    TableBody,
    TableHeader,
    useTableOptions,
} from "react-aria-components";

import {customCompare, formatDate, formatTime} from "../../utils/utils";


import FRENCH from "../../assets/images/FRENCH.jpg"
import ENGLISH from "../../assets/images/ENGLISH.jpg"
import DEUTSCH from "../../assets/images/DEUTSCH.jpg"
import RowOptionMenuTrigger from "./RowOptionMenuTrigger";




function MyTableHeader({columns, children}) {
    let {allowsDragging} = useTableOptions();

    return (
        <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md">
            {allowsDragging && <Column/>}
            <Collection items={columns}>
                {(column) => (
                    <Column className="p-1 text-white " key={column.id} isRowHeader={column.isRowHeader}>
                        {column.name}
                    </Column>
                )}
            </Collection>
            <Column></Column>
        </TableHeader>
    );
}

function MyRow({id, columns, item}) {
    return (
        <Row id={id} item={item} className='border-[#3f3f46] border-2 text-white'>
            {columns.map((col, index) => {
                let cellValue = checkColID(col.id, item)


                return <Cell key={index} className={col.id === 'ONLINE' ? "center h-[40px]" : "p-2"}>{cellValue}</Cell>;
            })}

            <Cell>
                <RowOptionMenuTrigger userData={item}   />
            </Cell>
        </Row>
    );
}

const flagLang = {
    "FRENCH" :  FRENCH,
    "ENGLISH" :  ENGLISH,
    "DEUTSCH" :  DEUTSCH
}

function checkColID(col, item) {
    switch (col) {
        case "TEXTURE": {
            return <img className="w-6 h-6 object-cover center rounded-[5px] shadowCustom"
                        src={`https://mc-heads.net/avatar/${item.UUID}`} alt={"test"}/>;
        }

        case "LANG": {
            return <img className="w-8 h-5 object-cover center rounded-[2px] shadowCustom"
                        src={flagLang[item.LANG]} alt={item.LANG}/>;
        }

        case "TIME_PLAYED" : {
            return formatTime(item[col]);
        }

        case "ONLINE" : {
            return item[col] ? <div className="center w-3 h-3 bg-green-500 rounded-full " ><span className=" center w-3 h-3 bg-green-500 rounded-full animate-ping"></span></div> :
                <div className="center w-3 h-3 bg-red  rounded-full"></div>
        }
        default :
            return item[col];
    }
}



    const TableComponents = (props) => {

    const columns = [
        {name: "SKIN", id: "TEXTURE"},
        {name: "NAME", id: "USERNAME", isRowHeader: true},
        {name: "GRADE", id: "GROUP"},
        {name: "IP", id: "HOST_IP"},
        {name: "LANG", id: "LANG"},
        {name: "ONLINE", id: "ONLINE"},
        {name: "TIME_PLAYED", id: "TIME_PLAYED"},
        {name: "UUID", id: "UUID"},
    ];


    let rows = [...props.data].sort(customCompare);


    return (

        <Table
        aria-label="doc"
        disabledBehavior="selection"
        className="w-full max-w-[100] max-h-[73vh]">
        <MyTableHeader columns={columns} />
         <TableBody
            className="text-center bg-gray text-white"
            renderEmptyState={() => "No results found."}
        >
        {
            rows.slice((props.currentPage * props.pageSize) - props.pageSize, props.currentPage * props.pageSize).map((item, index) => (
                <MyRow key={index} id={index} columns={columns} item={item}/>
            ))}
    </TableBody>
    </Table>

)
;
};



export default TableComponents;