import {Button, Dialog, DialogTrigger, Heading, Modal, ModalOverlay} from 'react-aria-components';
import { RiAlertFill } from "react-icons/ri";
import  React  from "react";
import {useDialog} from "react-aria";
const Alerte = () => {

    const { showDialog, closeDialog } = useDialog();
    return (

        <DialogTrigger onOpenChange={showDialog}>
                <ModalOverlay
                    className={({ isEntering, isExiting }) => `
          fixed inset-0 z-10 overflow-y-auto bg-black/25 flex min-h-full items-center justify-center p-4 text-center backdrop-blur
          ${isEntering ? 'animate-in fade-in duration-300 ease-out' : ''}
          ${isExiting ? 'animate-out fade-out duration-200 ease-in' : ''}
        `}
                >
                    <Modal
                        className={({ isEntering, isExiting }) => `
            w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl
            ${isEntering ? 'animate-in zoom-in-95 ease-out duration-300' : ''}
            ${isExiting ? 'animate-out zoom-out-95 ease-in duration-200' : ''}
          `}
                    >
                        <Dialog role="alertdialog" className="outline-none relative">
                            {({ close }) => (
                                <>
                                    <Heading
                                        slot="title"
                                        className="text-xxl font-semibold leading-6 my-0 text-slate-700"
                                    >
                                       Erreur CODE DUPLICATION
                                    </Heading>
                                    <div className=" text-2xl text-red-500 absolute right-0 top-0 stroke-2">
                                        <RiAlertFill  />
                                    </div>
                                    <p className="mt-3 text-slate-500">
                                       Vous ne pouvez pas avoir deux nom de CODE identique
                                    </p>
                                    <div className="mt-6 flex justify-end gap-2">
                                        <Button
                                            className="bg-neutral-500 rounded text-white p-2 text-slate-800 hover:border-slate-300 pressed:bg-slate-300"
                                            onPress={() => {
                                                close();
                                                closeDialog();
                                            }}
                                        >
                                            Fermer
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Dialog>
                    </Modal>
                </ModalOverlay>
            </DialogTrigger>
    );
}

export default Alerte