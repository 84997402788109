import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogTrigger, Heading,
    Input,
    Label,
    OverlayArrow,
    Popover,
    SearchField, Switch,
} from "react-aria-components";
import { useFilter } from 'react-aria';
import { FaFilter } from "react-icons/fa6";
import "../../global.css";
import { getAllAccountsData } from "../../services/services";

import Table from "./Table";

const Accounts = () => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(true);

    const [isOnline, setIsOnline] = useState('');
    const [isAdmin, setIsAdmin] = useState('');
    const [isMage, setIsMage] = useState('');
    
    const [currentPage, setCurrentPage] = useState(1);
    const pageSizeOptions = [20, 50, 80, 100];
    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAllAccountsData();

                     setData(result.data.data)

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);


    let { contains } = useFilter({
        sensitivity: 'base'
    });


    // TODO ajouter date range
    let matchedComposers = data.filter((composer) => {
        return (
            (value.length > 3 ?
                (contains(composer.USERNAME.toLowerCase().trim(), value.toLowerCase()) ||
                    contains(composer.UUID.toLowerCase().trim(), value.toLowerCase().trim()) ||
                    contains(composer.HOST_IP.toLowerCase().trim(), value.toLowerCase().trim()) ||
                    contains(composer.LANG.toLowerCase().trim(), value.toLowerCase().trim()))
             : composer) &&
            ((isOnline === '') || contains(composer.ONLINE.toString(), isOnline.toString())) &&
            ((isMage === '') || contains(composer.GROUP.includes('MAGE').toString(), isMage.toString())) &&
            (
                (isAdmin === '') ||
                contains(composer.GROUP.includes('STAFF').toString(), isAdmin.toString()) ||
                contains(composer.GROUP.includes('ADMIN').toString(), isAdmin.toString())
            )
        );
    });

    const resetFilters = () => {
        setIsOnline('');
        setIsAdmin('');
        setIsMage('');
    };

    const totalItems = matchedComposers.length;
    const totalPages = Math.ceil(totalItems / pageSize);

    const handlePageChange = (page) => {
        if(page === 0) return setCurrentPage(1);
        if(page > totalPages ) return setCurrentPage(totalPages);
        setCurrentPage(page);
    };


    return (
        <section >
            <h1 className="font-black text-[40px] uppercase">Liste des utilisateurs:</h1>
            <section className="bg-[#23282f] w-full h-[87vh] rounded-md p-2 shadowCustom overflow-hidden">
                <div className="flex gap-3 relative">
                    <SearchField>
                        <Label className="text-white">Rechercher un utilisateur </Label>
                        <Input
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value)
                                setCurrentPage(1)
                            }}
                        />
                        <Button onPress={() => setValue('')}>✕</Button>
                    </SearchField>

                    <DialogTrigger onOpenChange={()=>setCurrentPage(1)}>
                        <div className="flex flex-col">
                            <Label className="text-white">Filtre </Label>
                            <Button aria-label="Help"
                                    className='center h-[38.2px] bg-[#101010] px-2 rounded-md border-[#8f8f8f] border'><FaFilter
                                className="text-white"/></Button>
                        </div>
                        <Popover crossOffset={90} >
                            <OverlayArrow>
                                <svg width={12} height={12} viewBox="0 0 12 12">
                                    <path d="M0 0 L6 6 L12 0"/>
                                </svg>
                            </OverlayArrow>
                            <Dialog className="w-full p-2 outline-none ">
                                <div className="flex justify-between w-full pb-2">
                                    <Heading slot="title" className="text-xl">Filtre</Heading>
                                    <Button className="outline-none" onPress={() => {
                                        resetFilters()
                                    }}>Reset</Button>
                                </div>
                                <div className="flex flex-col gap-1  ">
                                    <Switch
                                        isSelected={isOnline}
                                        onChange={setIsOnline}
                                        className='react-aria-Switch flex justify-between'
                                    >
                                        Hors Ligne
                                        <div className="indicator"/>
                                        En ligne
                                    </Switch>

                                    <Switch
                                        isSelected={isAdmin}
                                        onChange={setIsAdmin}
                                        className='react-aria-Switch flex justify-between'
                                    >
                                        Joueur
                                        <div className="indicator"/>
                                        Staff
                                    </Switch>

                                    <Switch
                                        isSelected={isMage}
                                        onChange={setIsMage}
                                        className='react-aria-Switch flex justify-between'
                                    >
                                        Non Mage
                                        <div className="indicator"/>
                                        Mage
                                    </Switch>
                                </div>

                            </Dialog>
                        </Popover>
                    </DialogTrigger>
                    <div className="flex flex-col absolute right-1 top-0.25 text-white">
                        <Label className="text-white text-right">resultats </Label>
                        <p className=' center h-[38.2px] bg-[#101010] px-2 rounded-md border-[#8f8f8f] border'>{matchedComposers.length} / {data.length} trouvée</p>
                    </div>

                </div>

                <section
                    className="bg-[#2c2725] shadowCustom w-full h-[75vh] mt-2 rounded max-h-[73vh] !overflow-scroll">
                    <Table data={matchedComposers} loading={loading} pageSize={pageSize} currentPage={currentPage}/>
                </section>
                <div className="flex justify-between mt-2">
                    <div>
                        <Label className="text-white">Éléments par page: </Label>
                        <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            className="bg-[#101010] text-white px-2 rounded-md border-[#8f8f8f] border"
                        >
                            {pageSizeOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2 text-white">
                            <p className="">{`Page `}</p>
                            <Input className="bg-transparent w-8 text-center border border-white rounded outline-none" value={currentPage} onChange={(e) => { handlePageChange(Number(e.target.value)) }}/>
                            <p className="">{` / ${totalPages}`}</p>
                        </div>

                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-[#101010] text-white px-2 rounded-md border-[#8f8f8f] border"
                        >
                            Précédent
                        </button>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="bg-[#101010] text-white px-2 rounded-md border-[#8f8f8f] border"
                        >
                            Suivant
                        </button>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Accounts;