import React from 'react';
import Logo from "../../assets/logos/Logo_EL_Plan_de_travail_1_copie_4.png";
import {Link, NavLink} from "react-router-dom";
import getMessageMapping from "../../utils/Message";

const Error = ({code = 'Unknown_code'}) => {
    return (
        <div className="w-full h-[100vh] bg-red center">
            <section className="w-[52vh] h-2/2 bg-white rounded-xl center flex-col relative shadowCustom gap-3">
                <img className="w-[150px]" src={Logo} alt={"logo ElementaryLand"}/>
                <h1 className="text-[40px] text-center font-bold text-lg ">Erreur</h1>
                <h2 className="text-center font-bold text-lg ">{code}</h2>
                <div className="relative w-full h-40 center flex-col gap-2 mt-5">
                    <p className="absolute top-0.5">{getMessageMapping(code)}</p>
                    <a href="https://elementary-land.fr" target="_blank" rel="noopener noreferrer"
                       className="hover:bg-neutral-700 transition-bg duration-500 w-[40%] absolute bottom-5 left-5 bg-gray text-white p-1.5 rounded-md font-medium center">
                        Site ElementaryLand
                    </a>

                    <Link to={"/"} target="_blank" rel="noopener noreferrer"
                             className=" hover:bg-neutral-700 transition-bg duration-500 w-[40%] absolute bottom-5 right-5 bg-gray text-white p-1.5 rounded-md font-medium center">
                        Page acceuil
                    </Link>
                </div>
            </section>
        </div>
    )
}

export default Error