import React, {useState} from "react";
import {DialogTrigger, Button, Modal, Dialog, Heading, ListBox, ListBoxItem} from "react-aria-components";
import {FiPlus} from "react-icons/fi";
import {FaCopy} from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import FRENCH from "../../assets/images/FRENCH.jpg";
import ENGLISH from "../../assets/images/ENGLISH.jpg";
import DEUTSCH from "../../assets/images/DEUTSCH.jpg";
import {fetchUserDataFromAPI} from "../../services/services";
import {formatDate, formatTime} from "../../utils/utils";
import LoaderComponent from "../../components/Loader/Loader";
import SanctionList from "./SanctionList";
import Achievements from "./Achievements";


const RowOptionsMenuTrigger = (props) => {
    const {USERNAME,UUID,LANG,ONLINE,GROUP,HOST_IP,FIRST_SEEN,LAST_SEEN,LAST_UPDATED,TIME_PLAYED} = props.userData;

    const fetchDataAndOpenModal = async () => {
        try {

            const data = await fetchUserDataFromAPI(UUID);
            setModalData(data);
            setOpen(true)
        } catch (error) {
            setDisableButton(true);
            setButtonMessage(` Erreur`)
            console.error("Error fetching user data:", error);
        }
    };

    const [buttonMessage, setButtonMessage] = useState(<FiPlus/>);
    const [disableButton, setDisableButton] = useState(false);
    const [modalData, setModalData] = useState();
    const [isOpen, setOpen] = React.useState(false);
    const flagLang = {
        "FRENCH" :  FRENCH,
        "ENGLISH" :  ENGLISH,
        "DEUTSCH" :  DEUTSCH
    }

    return <DialogTrigger>
        <Button
            onPress={fetchDataAndOpenModal}
            isDisabled={disableButton}
            className="ring-1 ring-[#5b5c64] p-1 mr-1 rounded bg-[#52525b] hover:bg-[#3e3e45] transition bg duration-500">{buttonMessage}</Button>

            <Modal className="ring-1 ring-[#5b5c64] w-[60%] h-[70%] bg-[#212121] p-2 rounded" isOpen={isOpen}
                onOpenChange={setOpen}>
                { modalData && <Dialog className="w-full h-full">
                {({close}) => (

                    <div className="relative w-full h-full ">
                        <Heading slot="title" className="text-[25px] px-2 py-1 text-white font-bold uppercase"> Gestion
                                                                                                                de
                                                                                                                l'utilisateur</Heading>

                        <section className="grid grid-rows-3 grid-flow-col gap-4  w-full h-[87%]  text-white p-2">

                            <section className="row-span-3  h-full flex flex-col gap-2 justify-between max-w-[100%]">

                                <section className=" h-full p-1 flex justify-evenly border-b">
                                    <div className="w-[30%]">
                                        <div className="relative center">
                                            <img
                                                className="  w-[80%] h-full object-cover center rounded-[5px] shadowCustom"
                                                src={`https://mc-heads.net/avatar/${UUID}`} alt={USERNAME}/>
                                            {ONLINE ?
                                                <span
                                                    className="absolute bottom-[-10px] right-3 center w-6 h-6 bg-green-500 rounded-full ">
                                                     <span
                                                         className="center w-6 h-6 bg-green-500 rounded-full animate-ping"/></span> :
                                                <span
                                                    className="absolute bottom-[-10px] right-3 center w-6 h-6 bg-red  rounded-full"></span>}
                                        </div>
                                    </div>
                                    <div className="w-[70%] flex flex-col gap-2  ">
                                        <article className=" flex justify-between items-center">
                                            <h2>displayName : <span
                                                className="font-medium lowercase">{USERNAME}</span></h2>
                                            <Button onPress={() => {
                                                navigator.clipboard.writeText(USERNAME)
                                            }}
                                                    className="bg-transparent border-none hover:text-slate-600 transition-bg duration-500">
                                                <FaCopy/></Button>
                                        </article>
                                        <article className=" flex justify-between items-center ">
                                            <h2>UUID : <span className="font-medium lowercase">{UUID}</span></h2>
                                            <Button onPress={() => {
                                                navigator.clipboard.writeText(UUID)
                                            }}
                                                    className="bg-transparent border-none hover:text-slate-600 transition-bg duration-500">
                                                <FaCopy/></Button>
                                        </article>
                                        <article className="flex gap-2">
                                            LANGUAGE:
                                            <img className="w-8 h-5 object-cover center rounded-[2px] shadowCustom"
                                                 src={flagLang[LANG]} alt={LANG}/>
                                        </article>
                                        <article className="flex gap-2">GRADE: {GROUP} </article>
                                        <article className="flex gap-2"> IP: {HOST_IP}
                                        </article>
                                    </div>
                                </section>

                                <section className=" h-full p-1 flex h-full gap-5 items-center justify-around">
                                    <div className=" h-full flex flex-col gap-2 justify-between items-start w-50">
                                        <article className="flex gap-2">
                                            <p>Premiere connection</p>
                                            <p>{formatDate(FIRST_SEEN)}</p>
                                        </article>

                                        <article className="flex gap-2">
                                            <p>Dernière connection</p>
                                            <p>{formatDate(LAST_SEEN)}</p>
                                        </article>

                                        <article className="flex gap-2">
                                            <p>Dernière Mise a jour</p>
                                            <p>{formatDate(LAST_UPDATED)}</p>
                                        </article>

                                        <article className="flex gap-2">
                                            <p>Temps de jeux</p>
                                            <p>{formatTime(TIME_PLAYED)}</p>
                                        </article>
                                    </div>
                                    <div className="h-full w-[50%]">

                                        <article className="flex gap-2">
                                            <p>Currency: </p>
                                            <p>{modalData.currency.AMOUNT} coins</p>
                                        </article>

                                    </div>
                                </section>

                                <section className=" p-2 rounded-md bg-neutral-800 h-full overflow-scroll">
                                    {
                                          modalData.sanction &&
                                        <SanctionList data={modalData.sanction}/>
                                  }
                                </section>
                            </section>
                            <section className="col-span-3 bg-sky-500"><h3>Inventaire virtuel joueurs</h3></section>
                            <section className="row-span-2 col-span-3  p-2 rounded-md bg-neutral-800 h-full overflow-scroll">

                                {modalData.achievements && <Achievements data={modalData.achievements}/>}
                            </section>
                        </section>

                        <Button
                            className="absolute right-0 ring-1 ring-[#5b5c64] px-3 py-1 mr-1 text-white rounded bg-[#000] hover:bg-[#3e3e45] transition bg duration-500"
                            onPress={close}>
                            Fermer
                        </Button>
                    </div>
                    )}
            </Dialog>}
        </Modal>
    </DialogTrigger>
};

export default RowOptionsMenuTrigger;