import React, { useState } from 'react';
import Image from '../../assets/images/PANNEAU-INFO-VIDE.png';
import { Button } from 'react-aria-components';

const Info = () => {
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');
    const maxLength = 250;
    const handleInputChange = (event, setMessage) => {
        const inputValue = event.target.value;
        if (inputValue.length <= maxLength) {
            setMessage(inputValue);
        }
    };

    const handleSubmit = () => {
        // Send the messages to the API
        // Implement your API request logic here
        console.log('Sending messages to API:', message1, message2);
    };

    return (
        <>
            <h1 className="text-center font-black text-[40px] uppercase">modification du panneau d'information</h1>
            <section className="bg-[#23282f] w-full h-[87vh] rounded-md p-2 shadowCustom overflow-hidden ">
                <div className="w-full center gap-2">
                    <div className="w-full text-center">
                        <h3 className="text-white font-medium">Message panneau 1</h3>
                        <textarea
                            className="shadowCustom h-20 w-full resize-none rounded"
                            value={message1}
                            onChange={(e) => handleInputChange(e, setMessage1)}
                        />
                        <p className="text-white font-medium">{message1.length}/{maxLength}</p>
                        <Button onClick={handleSubmit} isDisabled={message1.length > maxLength}>Envoyer</Button>
                    </div>
                    <div className="w-full text-center">
                        <h3 className="text-white font-medium">Message panneau 2</h3>
                        <textarea
                            className="shadowCustom h-20 w-full resize-none rounded"
                            value={message2}
                            onChange={(e) => handleInputChange(e, setMessage2)}
                        />
                        <p className="text-white font-medium">{message2.length}/{maxLength}</p>
                        <Button onClick={handleSubmit} isDisabled={message2.length > maxLength}>Envoyer</Button>
                    </div>
                </div>

                <div className="relative center">
                    <div className="absolute overflow-hidden text-wrap text-center h-15 top-[220px] w-[58%] text-w font-medium">{message1}</div>
                    <div className="absolute overflow-hidden text-wrap text-center h-15 top-[310px] w-[58%] text-w font-medium">{message2}</div>
                    <img className="w-[75%] object-cover" src={Image} alt="image"/>

                </div>
            </section>
        </>
    );
};

export default Info;
