import {httpRequest} from "../utils/utils";
import UserDataModel from "./UserModel";
import axios from "axios";


const BaseURL = "https://backend.elementary-land.fr/api"

// const BaseURL = "http://localhost:4200/api";


export async function isAuthenticated() {
    return await httpRequest(`${BaseURL}/login/auth`, "GET", null, {});
}

export async function logout(id) {
    return await httpRequest(`${BaseURL}/login/logout/${id}`, "GET", null, {});
}

export async function login(data) {
    return await httpRequest(`${BaseURL}/login/login`, "POST", data);
}

export async function getAllAccountsData() {

    return await httpRequest(`${BaseURL}/data/getAll/`, 'GET', null, {});
}

export async function getAccountsData(id) {

    return await httpRequest(`${BaseURL}/data/getOne/${id}`, 'GET', null, {});
}


export async function getOneCurrencysData(uuid) {
    return await httpRequest(`${BaseURL}/currency/getOne/${uuid}`, 'GET', null, {});
}

export async function getOneAchievementsData(uuid) {
    return  await httpRequest(`${BaseURL}/achievements/getAll/${uuid}`, 'GET', null, {});
}

export async function getOneSanctionData(uuid) {
    return await httpRequest(`${BaseURL}/sanctionHistory/getOne/${uuid}`, 'GET', null, {});
}

export async function getAchievementsListData() {
    return  await httpRequest(`${BaseURL}/achievements/getAll`, 'GET', null, {});
}

export async function setAchievementsListData(data) {
    return  await httpRequest(`${BaseURL}/achievements/setAchievement`, 'POST', data, {});
}

export async function deleteAchievementsListData(code) {
    return  await httpRequest(`${BaseURL}/achievements/deleteAchievement`, 'DELETE', {code:code}, {});
}



export async function fetchUserDataFromAPI(userId) {
    try {
        const [currency, achievements, sanction] = await Promise.all([
            getOneCurrencysData(userId).catch((error) => {
                console.log(error)
                if (error.response && error.response.status === 404) {
                    
                    const currency = {
                        data:{
                            data:{
                                AMOUNT:0
                            }
                        }
                    }
                    if(error.response.data.CODE === 'USER_CURRENCY_NOT_FOUND') return currency;
                    console.error('Currency data not found:', error.message);
                    return  null;
                } else {
                    throw error;
                }
            }),
            getOneAchievementsData(userId).catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.error('Achievements data not found:', error.message);
                    return null;
                } else {
                    throw error;
                } 
            }),
            getOneSanctionData(userId).catch((error) => {
                if (error.response && error.response.status === 404) {
                    console.error('Sanction data not found:', error.message);
                    return null;
                } else {
                    throw error;
                }
            }),
        ]);

        let currencyModel = currency ? currency.data : null;
        let achievementsModel = achievements ? achievements.data : null;
        let sanctionModel = sanction ? sanction.data : null;

        if (!currencyModel || !achievementsModel || !sanctionModel) {
            throw new Error("Data not found for the given userId.");
        }

        return new UserDataModel(currencyModel, achievementsModel, sanctionModel);
    } catch (error) {
        // console.error('Error fetching user data from API:', error);
        throw error;
    }
}