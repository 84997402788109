import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

// import Loading from "../components/Loading/Loading.jsx";

function RouteGuard({children, checkFn}) {
    const navigate = useNavigate();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {

            try {
                const isAllowed = await checkFn();

                if (isAllowed.valid) {
                    setIsAuthorized(true);
                } else {
                    navigate("/error", {state: {code: isAllowed.response.data.code, status: isAllowed.status}});
                }
            } catch (error) {
                navigate("/error", {state: {code: error.response.data.code, status: error.response.status}});
            } finally {
                setLoading(false);
            }
        })();
    }, [checkFn, navigate]);

    if (loading) return 'chargement';


    return isAuthorized ? children : null;
}

RouteGuard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    checkFn: PropTypes.func.isRequired
};

export default RouteGuard;