import {Route, Routes} from "react-router-dom";
import RouteGuard from "./Guard/RouteGuard";
import {isAuthenticate} from "./services/authContext";

import Error from "./pages/Error/Error.jsx"
import SignIn from "./pages/SignIn/SignIn.jsx";
import ConnectedLayout from "./components/Layout/ConnectedLayout.jsx";
import Accounts from "./pages/Acounts/Accounts.jsx";
import Achievement from "./pages/Achievement/Achievement.jsx";
import Info from "./pages/Info/Info.jsx";

function App() {
    return (
        <Routes>
            {/*Accès connection*/}
            <Route path="/" element={<SignIn/>}/>
            {/*Accès compte*/}
            <Route path="/account" element={
                <RouteGuard checkFn={isAuthenticate}>
                    <ConnectedLayout/>
                </RouteGuard>}>

                <Route path='/account/' element={<Accounts/>}/>
                <Route path='/account/achievement/' element={<Achievement/>}/>
                <Route path='/account/info/' element={<Info/>}/>
            </Route>
            {/*Accès Error*/}
            <Route path="*" element={<Error/>}/>
        </Routes>
    );
}

export default App;
