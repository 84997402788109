import React from 'react';
import {NavLink, Outlet,useNavigate} from "react-router-dom";
import Logo from "../../assets/logos/logo_new_longnoir 3.png"
import './ConnectedLayout.css';
import Buttons from "../Button/Button";
import { FaUserLargeSlash } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import {logout} from "../../services/services";
import {toast} from "react-toastify";
import getMessageMapping from "../../utils/Message";


const ConnectedLayout = () => {
    const navigate = useNavigate();
    const id = localStorage.getItem('id')


        const handleLogOut =  async (e) => {

            const id = JSON.parse(localStorage.getItem('id'))

            try {
                const response = await logout(id);
                console.log(response)
                if(response.response.data.code === 'USER_LOGOUT') {
                    localStorage.clear();
                    toast.success(getMessageMapping(response.code));
                    navigate("/");
                }
            } catch (e) {
                toast.error(getMessageMapping('SERVER_NOT_RESPONDING'));
                console.log(e)
            }
        };






    return (
        <div className="flex flex-row">
            <section className="bg-gray h-[100vh] w-[30vh] shadowCustom center flex-col">
                <img className=" bg-white p-1 m-0.5 rounded-md w-[200px]  shadowCustom" src={Logo} alt="logo Elementary"/>

                <section className="pt-[100px] h-[83vh] w-full flex flex-col gap-5 items-center">
                    <div className="w-9/12">
                        <h2 className="mb-2 w-full bg-red py-3 px-5 center font-medium text-white rounded-md shadowCustom">ElementaryLand</h2>
                        {
                                 [
                                     {title:"accounts", link:'/account/'},
                                     {title:"achivement", link:'/account/achievement/'},
                                     {title:"info", link:'/account/info/'},
                                     {title:"gallery", link:'/account/gallery/'},
                                 ].map(({title,link},index) => NavLinkCustom(title,link,index))
                        }
                    </div>
                    <div className="w-9/12">
                        <h2 className="w-full bg-red py-3 px-5 center font-medium text-white rounded-md shadowCustom">siteWeb</h2>
                    </div>
                </section>
                <Buttons onClick={(e)=> handleLogOut(e)} withIcons={true} icon={<FaUserLargeSlash className="text-lg"/>} iconsPosition="left" variant="light" className="center text-red-700 !font-bold gap-3">Se Deconnecter</Buttons>
            </section>
            <main className="shadowCustom w-full p-5">
                <Outlet/>
            </main>
        </div>
    )
}

function NavLinkCustom(title, link, index) {
    return (
        <NavLink
            to={link}
            className={({ isActive }) => (
                [
                    isActive ? "underline" : "",

                    "text-white font-semibold flex gap-1 items-center"
                ].join(" ")
            )}
            key={index}
        >
            <FaAngleRight />
            {title}
        </NavLink>
    );
}

export default ConnectedLayout