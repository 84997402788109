export default function getMessageMapping(code, lang = {lang: "fr"}) {
    const messages = {
        'ACCESS_DENY': "Accès non autorisé",
        'ACCESS_DENY_OWNER_NOT_MATCH': "Aucune correspondance avec le jeton",
        'ACCESS_DENY_TOKEN_EXPIRED': "Jeton d'authentification expiré",
        'ACCOUNT_DISABLED': "Le compte est bien désactivé",
        'ACCOUNT_NOT_DISABLED': "Impossible de désactiver le compte",
        'ARTICLE_CREATED': "Votre Article a bien été créer",
        'ALLOWED_ACCESS': "Accès autorisé",
        'All_COMMENT_FOUND': "Tous les commentaires retournés",
        'All_COMMENT_NOT_FOUND': "Impossible de retourner les commentaires",
        'All_PROFILS_FOUND': "Tous les profils ont été chargés",
        'All_PROFILS_NOT_FOUND': "Aucun profil n'a été trouvé",
        'COMMENT_CREATED': "Votre commentaire est publié",
        'COMMENT_NOT_CREATED': "Erreur lors de la création de votre commentaire",
        'DOWNLOAD_ERROR': "Erreur lors du téléchargement du fichier",
        'DUPLICATION_ERROR': "Cet utilisateur existe déjà",
        'ERROR_COMPRESS': "Erreur lors de la compression de l'image",
        'ERR_BAD_REQUEST': "Erreur dans le format de la requete",
        'INCORRECT_FORMAT': "Format incorrect",
        'INCORRECT_PASSWORD': "Le mot de passe est incorrect",
        'INFO_NOT_FOUND': "Aucune donnée retournée par l'API pour l'information",
        'INVALID_DATA': "Le format des données envoyées est incorrect",
        'LIMIT_FILE_SIZE': "La taille du fichier dépasse la limite autorisée",
        'LOGIN_CORRECT': "Utilisateur connecté",
        'NO_TOKEN_PROVIDED': "Vous n'êtes pas authentifier",
        'PASSWORD_NOT_MATCH': "Le mot de passe ne correspond pas",
        'PROFILS_ADD_LIKED': "Like ajouté",
        'PROFILS_ADD_LIKED_ERROR': "Impossible d'ajouter le like",
        'PROFILS_BAN': "Compte banni",
        'PROFILS_FOUND': "Profil trouvé",
        'PROFILS_NOT_BAN': "Impossible de bannir le compte",
        'PROFILS_NOT_FOUND': "Profil introuvable",
        'PROFILS_UPDATE_PERFORM': "Mise à jour effectuée",
        'PROFILS_UPDATE_PERFORM_ERROR': "Erreur lors de la mise à jour",
        'SERVER_NOT_RESPONDING': "Le serveur ne répond pas",
        'TO_MANY_CONNECTION_ATTEMPTS': "Trop de tentatives de connexion. Le compte est banni. Veuillez contacter un administrateur",
        'TO_MANY_PICTURE_REQUEST': "Attendez quelques instants avant de poster à nouveau",
        'TO_MANY_REQUEST': "Trop de requêtes effectuées",
        'UPDATE_INFO_ERROR': "Erreur lors de la mise à jour des informations",
        'UNABLE_CREATE_AN_ACCOUNT': "impossible de crée un compte",
        'USER_ALLOWED': "Utilisateur autorisé",
        'USER_BANNED': "Utilisateur banni",
        'USER_CREATED': "Inscription réussie!",
        'USER_DISCONNECT': "Utilisateur déconnecté",
        'USER_LOGOUT': "Utilisateur déconnecté",
        'USER_NOT_ACTIVE': "Le compte n'est plus actif! Veuillez contacter un administrateur",
        'USER_NOT_ADMIN': "Cet utilisateur n'a pas les permissions administrateur",
        'USER_NOT_FOUND': "Utilisateur introuvable",
        'WRITE_COMPRESS_ERROR': "Erreur lors de l'écriture du fichier compressé",
        'ACHIEVEMENTS_ADD' : "Achievements ajoutée ",
        'ACHIEVEMENTS_DUPLICATION_ERROR' : "Le nom de code doit être unique"
    };

    const englishMessages = {
        'ACCESS_DENY': "Access denied",
        'ACCESS_DENY_OWNER_NOT_MATCH': "No match with the token",
        'ACCESS_DENY_TOKEN_EXPIRED': "Authentication token expired",
        'ACCOUNT_DISABLED': "The account is disabled",
        'ACCOUNT_NOT_DISABLED': "Unable to disable the account",
        'ARTICLE_CREATED': "Your article has been successfully created",
        'ALLOWED_ACCESS': "Allowed access",
        'All_COMMENT_FOUND': "All comments returned",
        'All_COMMENT_NOT_FOUND': "Unable to return comments",
        'All_PROFILS_FOUND': "All profiles have been loaded",
        'All_PROFILS_NOT_FOUND': "No profile found",
        'COMMENT_CREATED': "Your comment is published",
        'COMMENT_NOT_CREATED': "Error creating your comment",
        'DOWNLOAD_ERROR': "Error downloading the file",
        'DUPLICATION_ERROR': "This user already exists",
        'ERROR_COMPRESS': "Error compressing the image",
        'ERR_BAD_REQUEST': "Error in the request format",
        'INCORRECT_FORMAT': "Incorrect format",
        'INCORRECT_PASSWORD': "The password is incorrect",
        'INFO_NOT_FOUND': "No data returned by the API for the information",
        'INVALID_DATA': "Invalid data format sent",
        'LIMIT_FILE_SIZE': "File size exceeds the allowed limit",
        'LOGIN_CORRECT': "User logged in",
        'NO_TOKEN_PROVIDED': "You are not authenticated",
        'PASSWORD_NOT_MATCH': "Password does not match",
        'PROFILS_ADD_LIKED': "Like added",
        'PROFILS_ADD_LIKED_ERROR': "Unable to add like",
        'PROFILS_BAN': "Account banned",
        'PROFILS_FOUND': "Profile found",
        'PROFILS_NOT_BAN': "Unable to ban the account",
        'PROFILS_NOT_FOUND': "Profile not found",
        'PROFILS_UPDATE_PERFORM': "Update performed",
        'PROFILS_UPDATE_PERFORM_ERROR': "Error during update",
        'SERVER_NOT_RESPONDING': "The server is not responding",
        'TO_MANY_CONNECTION_ATTEMPTS': "Too many connection attempts. The account is banned. Please contact an administrator",
        'TO_MANY_PICTURE_REQUEST': "Wait a moment before posting again",
        'TO_MANY_REQUEST': "Too many requests made",
        'UPDATE_INFO_ERROR': "Error updating information",
        'UNABLE_CREATE_AN_ACCOUNT': "Unable to create an account",
        'USER_ALLOWED': "User allowed",
        'USER_BANNED': "User banned",
        'USER_CREATED': "Registration successful!",
        'USER_DISCONNECT': "User disconnected",
        'USER_LOGOUT': "User logged out",
        'USER_NOT_ACTIVE': "The account is no longer active! Please contact an administrator",
        'USER_NOT_ADMIN': "This user does not have administrator permissions",
        'USER_NOT_FOUND': "User not found",
        'WRITE_COMPRESS_ERROR': "Error writing the compressed file",
        'ACHIEVEMENTS_ADD' : "Achievements added ",
        'ACHIEVEMENTS_DUPLICATION_ERROR':'ACHIEVEMENTS DUPLICATION ERROR'
    };


    const languageCode = lang.lang.toLowerCase();

    const selectedMessages = languageCode === 'en-us' ? englishMessages : messages;

    return selectedMessages[code] || "Unknown error code";
}